/* Why FakeOff Section */
.why-fakeoff {
    padding: 80px 0;
    text-align: center;
    overflow-x: hidden; /* Prevent horizontal scroll issues */
}

.why-fakeoff h2 {
    font-size: 48px;
    margin-bottom: 40px;
    color: #fff;
}

.why-fakeoff h2 .highlight {
    color: #6a11cb;
    position: relative;
    display: inline-block;
}

.why-fakeoff h2 .highlight::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #6a11cb;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.why-fakeoff h2:hover .highlight::after {
    transform: scaleX(1);
}

/* Fixed Grid Layout */
.offer-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 20px;
}

/* Offer Card */
.offer {
    background-color: rgba(247, 242, 242, 0.032);
    padding: 25px;
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.offer h3 {
    font-size: 22px;
    margin-bottom: 15px;
    color: #6a11cb;
}

.offer p {
    font-size: 14px;
    color: #fff;
}

/* ======= RESPONSIVE FIXES ======= */
@media (max-width: 768px) {
    .offer-grid {
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); 
        gap: 20px;
        padding: 0 10px;
    }

    .why-fakeoff h2 {
        font-size: 32px;
    }

    .offer {
        padding: 20px;
    }

    .offer h3 {
        font-size: 18px;
    }

    .offer p {
        font-size: 12px;
    }
}
