/* Detection Section */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wdth,wght@98.1,700&display=swap');
body .detection {
    padding: 40px 0;
    text-align: center;
    min-height: 77vh;
    color: #fff;
    position: relative;
    z-index: 1;
    font-family: 'Poppins', sans-serif;
    overflow:hidden;
    
    
    /* Allow vertical scrolling within the component if needed */
}


.open-sans-test {
    font-family: "Open Sans", serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-variation-settings:
      "wdth" 98.1;
  }

  

body .detection {
    padding: 80px 0;
    text-align: center;
    height: 100%;
    color: #fff;
    position: relative;
    z-index: 1;
    font-family: 'Inter', sans-serif;
}

body .detection .container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 20px;
}

body .detection .title {
    font-family: "Open Sans", serif;
    font-size: 63px;
    margin-bottom: -20px;
    margin-top: 40px;
    color: #fff;
    font-weight: 700;
    display: inline-block;
    overflow: hidden; /* Ensure the animation doesn't overflow */
    text-align: center;
  
  
  
  color: #fff;
}

body .detection .title span {
    display: inline-block; /* Ensure each word is on the same line */
    
}

body .detection .subtitle {
    
    font-size: 20px;
    margin-bottom: 30px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
    animation: fadeInText 1s forwards 0.9s;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    
}

body .detection-box {
    background-color: #b77f7f00; /* Transparent background */
    padding: 30px;
    border-radius: 20px;
    
    display: inline-block;
    width: 90%;
    max-width: 600px;
    max-height: 10000px;
    opacity: 0;
    animation: fadeInText 1s forwards 1.5s;
}

body .detection-box.has-preview {
    padding: 10px;
}

body .detection-box .image-container {
    position: relative;
    margin-bottom: 10px;
    border-radius: 15px;
    overflow: hidden;
}

body .detection-box .image-preview {
    max-width: 100%;
    max-height: 300px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}



body .detection-box .upload-btn,
body .detection-box .detect-btn {
    display: inline-block;
    padding: 12px 24px;
    
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-bottom: 20px;
    margin-top:15px;
    padding: 12px 30px;
    background-image: linear-gradient(to right, #6a11cb, #2575fc);
    color: #fff;
    border-radius: 30px;
    font-size: 16px;
    text-decoration: none;
    opacity: 1;
    /* Appear after the paragraph */
}



body .detection-box .drag-text {
    font-size: 14px;
    color: #ccc;
    margin-top: -5px;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

body .detection-box .result {
    font-size: 23px;
    font-weight: 600;
    
    color: #dde9de;
    margin-top: 20px;
    overflow: hidden;       /* Hide the overflowing text during typing */
    white-space: nowrap;    /* Prevent text from breaking */
     /* Create a caret effect */
    display:inline-flexbox;
    width: 0; /* Start with zero width */
    
     /* Allow the text to take up the full width */
     @keyframes typing {
        0% { width: 0; }
        50% { width: 50%; }
        100% { width: 100%; }
    }
    
    animation: typing 2s ease-in-out forwards;
    font-family: 'Poppins', sans-serif;
}

@keyframes typing {
    from { width: 0; } /* Start with zero width */
    to { width: 100%; } /* Expand to full width */
}

@keyframes blink-caret {
    from, to { border-color: transparent; } /* Hide caret */
    50% { border-color: #ffffff; } /* Show caret */
}

@keyframes pop {
    0% { transform: scale(0.9); opacity: 0; }
    100% { transform: scale(1); opacity: 1; }
}

@keyframes text-gradient {
    0% { background-position: 0% 50%; }
    100% { background-position: 100% 50%; }
}

@keyframes float {
    0% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0); }
}

body .detection-box.dragover {
    border: 2px dashed #4CAF50;
    background-color: #2e2e2e;
}

@keyframes blink-caret {
    from, to {
        border-color: transparent;
    }
    50% {
        border-color: #fff;
    }
}

/* Fade-in animation with a slight upward movement */
@keyframes fadeInText {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

body .detection-box .choose-another-btn {
    display: inline-block;
    padding: 12px 24px;
    
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-bottom: 20px;
    padding: 12px 30px;
    background-image: linear-gradient(to right, #6a11cb, #2575fc);
    color: #fff;
    border-radius: 30px;
    font-size: 16px;
    text-decoration: none;
    opacity: 0;
    animation: fadeInText 1s forwards 2.8s;
}

body .detection-box .choose-another-btn:hover {
    background-color: #45a049;
    transform: translateY(-3px);
}