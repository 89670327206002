/* What is FakeOff Section */
.what-is-fakeoff {
    padding: 90px 0;
    background-color: #090809;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}

/* Left column: Text area */
.fakeoff-text {
    flex: 0 1 60%;
    text-align: left;
    padding-left: 80px;
    font-family: 'Poppins', sans-serif;
    color: #fff;
    opacity: 0; /* Start hidden */
    transform: translateX(-250px); /* Start off-screen */
    transition: opacity 1.5s ease, transform 1.5s ease;
}

/* Trigger animations only when the section is in view */
.what-is-fakeoff.in-view .fakeoff-text {
    opacity: 1;
    transform: translateX(0);
}

/* Keep "FakeOff?" together on one line */
.fakeoff-highlight {
    white-space: nowrap;
}

/* Heading styling */
.fakeoff-text h2 {
    font-size: 36px;
    margin-bottom: 20px;
    color: #fff;
    opacity: 0; /* Start hidden for animation */
    transform: translateY(-20px); /* Start slightly above */
    transition: opacity 1.5s ease, transform 1.5s ease;
}

.what-is-fakeoff.in-view .fakeoff-text h2 {
    opacity: 1;
    transform: translateY(0);
}

/* Underline animation for "FakeOff" */
.fakeoff-text h2 .underline-animation {
    color: #6a11cb;
    position: relative;
    display: inline-block;
}

.fakeoff-text h2 .underline-animation::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
    height: 3px;
    width: 0;
    background-color: #6a11cb;
}

.what-is-fakeoff.in-view .underline-animation::after {
    animation: underlineExpand 2s forwards;
    animation-delay: 0.5s;
}

/* Style for the question mark */
.fakeoff-text h2 .question-mark {
    color: #6a11cb;
    margin-left: 2px;
}

/* Paragraph styling */
.fakeoff-text p {
    font-size: 20px;
    max-width: 900px;
    margin: 0;
    color: #fff;
    opacity: 0; /* Start hidden for animation */
    transform: translateY(20px); /* Start slightly below */
    transition: opacity 1.5s ease 1s, transform 1.5s ease 1s;
}

.what-is-fakeoff.in-view .fakeoff-text p {
    opacity: 1;
    transform: translateY(0);
}

/* Right column: Image area */
.fakeoff-demo {
    flex: 0 1 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

/* Logo image */
.logo-image {
    max-width: 60%;
    height: auto;
    border-radius: 70px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    opacity: 0; /* Start hidden */
    transform: scale(0.9); /* Start slightly smaller */
    transition: opacity 1.5s ease, transform 1.5s ease;
}

.what-is-fakeoff.in-view .logo-image {
    opacity: 1;
    transform: scale(1);
}

/* Hover effect */
.logo-image:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.what-is-fakeoff a.btn {
    padding: 12px 30px;
    background-image: linear-gradient(to right, #6a11cb, #2575fc);
    color: #fff;
    border-radius: 30px;
    font-size: 16px;
    text-decoration: none;
    margin-top: 30px;
    opacity: 0; /* Start hidden */
    transform: translateY(20px); /* Start slightly below */
    transition: opacity 1.5s ease 2.4s, transform 1.5s ease 2.4s;
}

.what-is-fakeoff.in-view a.btn {
    opacity: 1;
    transform: translateY(0);
}

/* Button hover effect */
.what-is-fakeoff a.btn:hover {
    background-image: linear-gradient(to right, #7025fc, #6a11cb);
}

/* ========= Mobile Optimization with Animations ========= */
@media (max-width: 768px) {
    .what-is-fakeoff {
        flex-direction: column;
        text-align: center;
        padding: 50px 20px;
        gap: 20px;
    }

    .fakeoff-text {
        padding-left: 0;
        flex: 1;
    }

    .fakeoff-text h2 {
        font-size: 28px;
    }

    .fakeoff-text p {
        font-size: 16px;
        max-width: 100%;
    }

    .fakeoff-demo {
        flex: none;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .logo-image {
        max-width: 80%;
    }

    .what-is-fakeoff a.btn {
        padding: 10px 25px;
        font-size: 14px;
    }
}

/* Keyframes: Underline expansion */
@keyframes underlineExpand {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}