@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Poppins:wght@500;600&display=swap");

.header {
    position: fixed;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    border-radius: 18px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.4s ease-in-out;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 47px;
    border: 2px solid #6a11cb;
    cursor: pointer;
    overflow: hidden;
}

.logo-text {
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #080809;
    transition: opacity 0.3s ease-in-out;
}

/* Hide text when expanded */
.hidden {
    opacity: 0;
}

/* Navigation Menu */
.header-nav {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
    padding: 10px 20px;
}

/* Menu Items */
.header-nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 30px;
    white-space: nowrap;
}

.header-nav ul li a {
    color: #333;
    text-decoration: none;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    transition: color 0.3s ease;
}

.header-nav ul li a:hover {
    color: #6a11cb;
}

/* Expanded Navigation */
.header.expanded {
    width: min(90vw, 600px); /* Ensures it doesn't overflow */
    border-radius: 20px;
    top: 60px;
}

/* Make sure it fits perfectly inside the screen */
.header.expanded .header-nav {
    opacity: 1;
    visibility: visible;
    width: 83%;
    display: flex;
    justify-content: center;
}

/* Fade-in effect for menu items */
@keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
}

.header.expanded .header-nav ul li {
    animation: fadeIn 0.4s ease-in-out forwards;
    opacity: 0;
}

.header.expanded .header-nav ul li:nth-child(1) { animation-delay: 0.1s; }
.header.expanded .header-nav ul li:nth-child(2) { animation-delay: 0.2s; }
.header.expanded .header-nav ul li:nth-child(3) { animation-delay: 0.3s; }

/* Mobile-Specific Styling */
@media (max-width: 768px) {
    .header {
        width: 160px;
        height: 47px;
        overflow: visible;
        top: 60px;
    }

    /* Navbar expands with perfect centering */
    .header.expanded {
         /* Now fits within the screen properly */
        max-width: 200px; /* Prevents it from being too wide */
        
        padding: 15px;
        border-radius: 20px;
        top: 70px;
        
       
    }

    .header.expanded .logo-text {
        display: none;
    }

    .header-nav {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
        left: 0;
        transform: none;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
    }

    .header.expanded .header-nav {
        opacity: 1;
        visibility: visible;
    }

    .header-nav ul {
        flex-direction: column;
        gap: 15px;
    }
}
