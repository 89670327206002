body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    color: #080809;
    line-height: 1.6;
    background-color: #090809;
    position: relative;
    
}

body::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: 
        radial-gradient(#6a11cb 1px, transparent 1px),
        radial-gradient(#6a11cb 0.5px, transparent 1px);
    background-size: 60px 60px;
    background-position: 0 0, 25px 25px;
    animation: particleMove 10s linear infinite;
    opacity: 1;
}

@keyframes particleMove {
    0% { background-position: 0 0, 25px 25px; }
    100% { background-position: 50px 50px, -15px -25px; }
}
